import { pickBy } from 'lodash'
import { MEDIA_FILE_FILTERS, DEFAULT_HOTKEYS, HOTKEYS, EMPTY_HOTKEY_COMBINATION, EXCLUDE_HOTKEY } from 'enums'


const getFilterValue = (vrspotValue: string, nonVrspotValue: string = vrspotValue) => __APP_PROFILE__ === 'vrspot'
  ? vrspotValue
  : nonVrspotValue

export const MEDIA_FILE_FILTERS_ORDERED: Partial<Record<keyof typeof MEDIA_FILE_FILTERS, string | boolean>> = pickBy({
  All: __CFG__.SOURCE_FILES_FILTERS.ALL && getFilterValue('All'),
  Video: __CFG__.SOURCE_FILES_FILTERS.VIDEO && getFilterValue('Videos', 'Video'),
  Image: __CFG__.SOURCE_FILES_FILTERS.IMAGES && getFilterValue('Images', 'Image'),
  Audio: __CFG__.SOURCE_FILES_FILTERS.AUDIO && getFilterValue('Audio Files', 'Audio'),
  Voiceover: __CFG__.SOURCE_FILES_FILTERS.MEDIA_RECORDING.VOICEOVER
    && __APP_PROFILE__ !== 'vrspot' && getFilterValue('Voiceover'),
  Folder: __CFG__.SOURCE_FILES_FILTERS.FOLDERS && getFilterValue('Folders', 'Folder'),
}, Boolean)


// Prepares a set of default hotkeys with empty values and excludes some
export const switchUsedHotkeys = ({
  switchOffKeys = [],
  excludedKeys = [],
}: {
  switchOffKeys: Array<HotkeysType>,
  excludedKeys?: Array<HotkeysType>
}) => (
    Object.keys(DEFAULT_HOTKEYS) as Array<HotkeysType>).reduce((result, key) => {
  // eslint-disable-next-line no-param-reassign
  result[key] = !switchOffKeys.includes(key)
    ? DEFAULT_HOTKEYS[key as typeof HOTKEYS[HotkeysType]]
    : EMPTY_HOTKEY_COMBINATION
  if (excludedKeys.includes(key)) {
    // eslint-disable-next-line no-param-reassign
    result[key] = EXCLUDE_HOTKEY
  }
  return result
}, {} as Record<HotkeysType, string>)

export const mergeHotkeys = ({
  customHotkeys,
  defaultHotkeys,
}: {
  customHotkeys: Record<HotkeysValueType, string>
  defaultHotkeys: Record<HotkeysValueType, string>
}) => Object.entries(defaultHotkeys).reduce((result, [ key, defaultCombination ]) => {
  if (!Object.hasOwn(customHotkeys, key)) {
    const hasConflict = Object
      .values(customHotkeys)
      .find(customCombination => customCombination === defaultCombination)
    // eslint-disable-next-line no-param-reassign
    result[key as HotkeysValueType] = hasConflict ? EMPTY_HOTKEY_COMBINATION : defaultCombination
  } else {
    // eslint-disable-next-line no-param-reassign
    result[key as HotkeysValueType] = customHotkeys[key as HotkeysValueType]
  }
  return result
}, { ...customHotkeys } as Record<HotkeysValueType, string>)
