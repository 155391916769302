import * as classNames from 'classnames'
import { TranslationContext } from 'contexts/TranslationContext'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Actions from '~/actions'
import * as ActionTypes from '~/actions/ActionTypes'
import { ReactComponent as IconCopy } from '~/assets/projects/copy_icon.svg'
import { ReactComponent as IconDelete } from '~/assets/projects/delete_icon.svg'
import { ReactComponent as IconEdit } from '~/assets/projects/edit_icon.svg'
import { useProjectItemProps } from '~/components/Projects/hooks/useProjectItemProps'
import Button from '~/components/base/Button'
import { FormattedLatestDate } from '~/components/base/FormattedLatestDate/FormattedLatestDate'
import { Img } from '~/components/base/Img/Img'
import Input from '~/components/base/Input'
import { useAction, useModalDialog, usePositiveEffect } from '~/hooks'
import { selectRenamingProjectIdInList } from '~/selectors/projectData'
import { DeleteProjectConfirmation } from '../DeleteProjectConfirmation/DeleteProjectConfirmation'
import { ProjectItemPreview } from '../ProjectItemPreview'
import './ProjectItem.scss'
import { ProjectContextMenuComponent } from '~/components/Projects/components/ContextMenu/ProjectContextMenu'
import { ProjectMenuContext } from '~/contexts/ProjectContext'
import { useProjectContextMenu } from '~/components/Projects/hooks/useProjectContextMenu'

const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/

export const ProjectItem = ({ project, onClick, selected, removeSelection }) => {
  const deleteProject = useAction(Actions.deleteProject.deleteProject, project.id)
  const renameProject = useAction(Actions.renameProject.renameProject, project.id)
  const duplicateProject = useAction(Actions.duplicateProject.duplicateProject)
  const setRenamingProjectInList = useAction(
    payload => dispatch => dispatch({ type: ActionTypes.PROJECTS_SET_RENAMING_ID_IN_LIST, payload })
  )

  const renamingProjectIdInList = useSelector(selectRenamingProjectIdInList)
  const shouldStartRenaming = renamingProjectIdInList === project.id

  const [ newName, setNewName ] = useState(() => project.name)
  const [ isRenaming, setIsRenaming ] = useState(false)

  const { t } = useContext(TranslationContext)
  const { handleContextMenu } = useContext(ProjectMenuContext)
  const { refProject, openContextMenu } = useProjectContextMenu({ handleContextMenu })

  const isBase64Thumbnail = useMemo(
    () => base64regex.test(project.thumbnail), [ project.thumbnail ]
  )

  const startRenaming = useCallback(() => {
    setNewName(project.name)
    setIsRenaming(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onInputChange = e => {
    setNewName(e.target.value)
  }

  const finishRenaming = () => {
    removeSelection()
    setIsRenaming(false)
    setRenamingProjectInList(null)
  }

  const saveName = () => {
    finishRenaming()
    const trimmedName = newName.trim()
    if (!!trimmedName && trimmedName !== project.name) {
      renameProject(trimmedName, true)
    }
  }

  const onSubmit = e => {
    e.preventDefault()
    saveName()
  }

  const deletionDialog = useModalDialog({
    onConfirm: deleteProject,
  })

  const handleEscKey = () => {
    finishRenaming()
  }

  const handleClickCopy = () => {
    duplicateProject(project.id)
    handleContextMenu()
  }

  const handleClickRename = () => {
    setRenamingProjectInList(project.id)
    handleContextMenu()
  }

  const stopPropagation = e => {
    e.stopPropagation()
  }

  const projectItemProps = useProjectItemProps(project, { onClick })

  usePositiveEffect(startRenaming, [ shouldStartRenaming ])

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <div ref={refProject} onContextMenu={e => openContextMenu(e, project.id)} className={classNames('project-item', { 'project-item--active': selected, 'project-item--renaming': isRenaming })} {...projectItemProps}>
        <If condition={project.thumbnail}>
          <Img isBase64={isBase64Thumbnail} src={project.thumbnail} className="project-item__image" alt="thumbnail" />
        </If>
        <If condition={!project.thumbnail}>
          <ProjectItemPreview />
        </If>

        <If condition={!isRenaming}>
          <div className="project-item__name__text">
            {project.name}
          </div>
          <div className="project-item__date">
            <FormattedLatestDate prefix={t('PROJECTS_PREFIX_TEXT_EDITED')} date={project.modifTime} />
            {project.modifiedDate}
          </div>
        </If>
        <If condition={isRenaming}>
          <form onClick={stopPropagation} className="project-item__name__form" onSubmit={onSubmit}>
            <Input autoFocus onEscKeyDown={handleEscKey} className="project-item__name__input" onChange={onInputChange} value={newName} onBlur={saveName} />
            <div className="project-item__date" title={t('PROJECTS_BTN_SAVE_TOOLTIP')}>
              <Button
                border
                type="submit"
                className="project-item__name__save-btn"
                data-lang-id="PROJECTS_BTN_SAVE"
              >
                {t('PROJECTS_BTN_SAVE')}
              </Button>
            </div>
          </form>
        </If>
        <div onClick={stopPropagation} className="project-item__actions">
          <IconEdit title={t('PROJECTS_TITLE_TEXT_RENAME')} onClick={handleClickRename} />
          <IconDelete title={t('PROJECTS_TITLE_TEXT_DELETE')} onClick={deletionDialog.open} />
          <IconCopy title={t('PROJECTS_TITLE_TEXT_COPY')} onClick={handleClickCopy} />
        </div>
        <ProjectContextMenuComponent
          rename={handleClickRename}
          remove={() => {
            deletionDialog.open()
            handleContextMenu()
          }}
          copy={handleClickCopy}
          projectId={project.id}
        />
      </div>
      <DeleteProjectConfirmation modalDialog={deletionDialog} name={project.name} />
    </>
  )
}
