/* eslint-disable quote-props */
/* eslint-disable quotes */
export const translations = { en: {
  "ADD_FOLDER_BTN": "Add folder",
  "ADD_FOLDER_BTN_TOOLTIP": "Add folder",
  "AMAZON_IMPORT_PLACEHOLDER_BUCKET_NAME": "Bucket Name",
  "AMAZON_IMPORT_PLACEHOLDER_ACCESS_ID_KEY": "Access Key ID",
  "AMAZON_IMPORT_PLACEHOLDER_SECRET_KEY": "Secret Key",
  "AMAZON_IMPORT_PROGRESS_TEXT_IMPORTING": "Importing",
  "AMAZON_IMPORT_CONDITION_TEXT_IMPORT_ERROR": "Import error",
  "AMAZON_IMPORT_CONDITION_TEXT_IMPORTED_SUCCESSFULLY": "Imported sucessfully",
  "AMAZON_IMPORT_HEAD_TEXT_IMPORT_MEDIA_FROM_AWS": "Import media from AWS Bucket",
  "AMAZON_IMPORT_BTN_IMPORT": "Import",
  "AMAZON_IMPORT_BTN_IMPORT_TOOLTIP": "Import",
  "AUDIO_CAPTION_AUDIO": "Audio",
  "AUDIO_TEXT_FOUND_ITEMS": "Found items",
  "AUDIO_TEXT_NO_RESULTS_FOUND": "No results found",
  "AUDIO_TEXT_CANNOT_FIND_RESULTS": "It seems we can’t find any results based on your search.",
  "AUDIO_SETTINGS_FORM_TITLE_SETTINGS_AUDIO": "Audio settings",
  "AUDIO_SETTINGS_FORM_CAPTION_SOUND": "Sound",
  "AUDIO_SETTINGS_FORM_CAPTION_TRANSITION": "Transition",
  "AUDIO_SETTINGS_FORM_LABEL_FADE_IN": "Fade in",
  "AUDIO_SETTINGS_FORM_LABEL_FADE_OUT": "Fade out",
  "AUDIO_INPUT_PLACEHOLDER_SEARCH_GENRE": "Search genre",
  "AUDIO_INPUT_PLACEHOLDER_SEARCH_MUSIC": "Search music",
  "AUDIO_INPUT_PLACEHOLDER_SEARCH_SOUND_EFFECTS": "Search sound effects",
  "AUDIO_INPUT_PLACEHOLDER_SEARCH_IN": "Search in",
  "AUDIO_LIST_CATEGORIES_MUSIC_BY_GENRE": "Music by Genre",
  "AUDIO_LIST_CATEGORIES_SOUND_EFFECTS": "Sound Effects",
  "AUDIO_LIST_CATEGORIES_MUSIC_BY_MOOD": "Music by Mood",
  "AUDIO_MENU_ITEM_ALL": "All",
  "BASE_ASSETS_SETTINGS_TEXT_REMOVE_ASSETS_FROM_TIMELINE": "Remove asset from timeline?",
  "COLOR_PICKER_BTN_COLOR": "Select color",
  "COLOR_PICKER_BTN_COLOR_TOOLTIP": "Select color",
  "COLOR_PICKER_TEXT_TEXT_BACKGROUND_COLOR": "Background color",
  "COLOR_PICKER_BACKGROUND_COLOR_TOOLTIP": "Background color",
  "COMMON_STATUS_TEXT_DOWNLOADING_MEDIA_FILES": "Downloading media files",
  "COMMON_STATUS_TEXT_UPLOADING_SAVED_MEDIA": "Uploading saved media",
  "COMMON_STATUS_TEXT_ABORTED": "Aborted",
  "COMMON_STATUS_TEXT_LOADING": "Loading",
  "COMMON_STATUS_TEXT_RENDERING": "Rendering",
  "CONFIRMATION_DIALOG_BTN_CANCEL": "Cancel",
  "CONFIRMATION_DIALOG_BTN_CANCEL_TOOLTIP": "Cancel",
  "CUSTOMIZATION_FORM_CAPTION_USER_AVATAR": "User avatar",
  "CUSTOMIZATION_FORM_FIELD_DESC_YOU_CAN_UPLOAD_AVATAR": "You can upload a file of default user avatar.",
  "CUSTOMIZATION_FORM_FIELD_NAME_AVATAR": "Avatar",
  "CUSTOMIZATION_FORM_CAPTION_COLOR_THEME": "Color theme",
  "CUSTOMIZATION_FORM_CAPTION_CUSTOM_JS_SCRIPT": "Custom JS script",
  "CUSTOMIZATION_FORM_CAPTION_CUSTOM_TEXT": "Custom text",
  "CUSTOMIZATION_FORM_FIELD_DESC_YOU_CAN_CUSTOMIZE_TEXT": "You can customize the editor's text",
  "CUSTOMIZATION_FORM_FIELD_DESC_THIS_SCRIPT_RUNS_ON_LOADING": "This script runs on editor's loading. Can be used to integrate analytics etc.",
  "CUSTOMIZATION_TITLE_EDIT_JS_SCRIPT": "Edit JS script",
  "CUSTOMIZATION_TITLE_SECOND_ACCENT_COLOR": "Second accent color",
  "CUSTOMIZATION_DESC_SELECT_BG_COLOR": "Select the color of the main background.",
  "CUSTOMIZATION_DESC_MAIN_ACCENT_COLOR_FOR_CONTROLS": "The main accent color for the controls. Select your brand color here.",
  "CUSTOMIZATION_DESC_ADDITIONAL_COLOR_FOR_CONTROLS": "Additional color of controls in pair with the main color of buttons.",
  "CUSTOMIZATION_DESC_AUDIO_FILES_STYLE": "The audio file on the timeline has its own style.",
  "CUSTOMIZATION_DESC_YOU_CAN_UPLOAD_LOGO": "You can upload a file of your Logo.",
  "CUSTOMIZATION_DESC_VOICE_OVER_STYLE": "The voiceover file has its own style.",
  "CUSTOMIZATION_LABEL_DISPLAYED": "displayed",
  "CUSTOMIZATION_TITLE_CHOOSE_THEME": "Choose one of the predefined themes or create your own. You can edit the CSS file to customize your theme.",
  "CUSTOMIZATION_TITLE_BASIC_BG": "Basic background",
  "CUSTOMIZATION_TITLE_PRIMARY_BTNS": "Primary buttons, links",
  "CUSTOMIZATION_TITLE_EDIT_CSS_FOR_THEME": "Edit CSS file for Theme",
  "CUSTOMIZATION_TITLE_AUDIO": "Audio",
  "CUSTOMIZATION_TITLE_VOICE_OVER": "Voice over",
  "CUSTOMIZATION_TITLE_EDIT_TEXT_VALUES": "Edit text values",
  "CUSTOMIZATION_BTN_EDIT_TEXT_VALUES": "Edit text values",
  "CUSTOMIZATION_BTN_EDIT_TEXT_VALUES_TOOLTIP": "Edit text values",
  "CUSTOMIZATION_BTN_FORMAT_DESCRIPTION": "Format description",
  "CUSTOMIZATION_BTN_FORMAT_DESCRIPTION_TOOLTIP": "Format description",
  "CUSTOMIZATION_BTN_RESET_ALL_CHANGES": "Reset all changes",
  "CUSTOMIZATION_BTN_RESET_ALL_CHANGES_TOOLTIP": "Reset all changes",
  "CUSTOMIZATION_BTN_CANCEL": "Cancel",
  "CUSTOMIZATION_BTN_CANCEL_TOOLTIP": "Cancel",
  "CUSTOMIZATION_BTN_SAVE": "Save",
  "CUSTOMIZATION_BTN_SAVE_TOOLTIP": "Save",
  "CUSTOMIZATION_BTN_EDIT_JS_SCRIPT": "Edit JS script",
  "CUSTOMIZATION_BTN_EDIT_JS_SCRIPT_TOOLTIP": "Edit JS script",
  "CUSTOMIZATION_BTN_EDIT_CSS": "Edit CSS",
  "CUSTOMIZATION_BTN_EDIT_CSS_TOOLTIP": "Edit CSS",
  "CUSTOMIZATION_BTN_UPLOAD_LOGO": "Upload logo",
  "CUSTOMIZATION_BTN_UPLOAD_LOGO_TOOLTIP": "Upload logo",
  "CUSTOMIZATION_BTN_DELETE_LOGO": "Delete logo",
  "CUSTOMIZATION_BTN_DELETE_LOGO_TOOLTIP": "Delete logo",
  "CUSTOMIZATION_CSS_OPTION_DARK_THEME": 'Dark theme',
  "CUSTOMIZATION_CSS_OPTION_LIGHT_THEME": 'Light theme',
  "CUSTOMIZATION_CSS_OPTION_CUSTOM_THEME": 'Custom theme',
  "CUSTOMIZATION_FORM_FIELD_NAME_LOGO": "Logo",
  "CUSTOMIZATION_FORM_CAPTION_LOGO": "Logo",
  "CUSTOMIZATION_CAPTION_CUSTOMIZE_EDITOR": "Customize editor",
  "CUSTOMIZATION_CAPTION_LOGO_AND_NAME": "Logo and name",
  "CUSTOMIZATION_CAPTION_COMPANY_NAME": "Company name",
  "CUSTOMIZATION_CAPTION_EDITOR_NAME": "Editor name",
  "CUSTOMIZATION_TEXT_ACCEPTED_FILES": "Accepted files:",
  "CUSTOMIZATION_TEXT_NO_UPLOADED_LOGO": "No uploaded logo",
  "DUMMY_HEADER_BTN_VIEW_SETTINGS_TOOLTIP": "View Settings",
  "DUMMY_HEADER_BTN_VIEW_SETTINGS": "View Settings",
  "DUMMY_HEADER_BTN_FILTERS_TOOLTIP": "Filters",
  "DUMMY_HEADER_BTN_FILTERS": "Filters",
  "EDITOR_MENU_LIST_ITEM_SAVE_PROJECT_AS": "Save project as..",
  "EDITOR_MENU_LIST_ITEM_NEW_PROJECT": "New project",
  "EDITOR_MENU_LIST_ITEM_PUBLISH": "Publish",
  "EDITOR_MENU_LIST_ITEM_CUSTOMIZE_EDITOR": "Customize editor",
  "EDITOR_MENU_TITLE_UNABLE_TO_PUBLISH": "Unable to publish",
  "EDITOR_MENU_TEXT_VIDEO_TRACK_IS_REQUIRED": "Track is required",
  "FILE_INPUT_BTN": "File input",
  "FILE_INPUT_BTN_TOOLTIP": "File input",
  "FORM_FIELD_LABEL_VOLUME": "Volume",
  "HEAD_CONTROL_IMPORT_TEXT": "Import",
  "HEAD_CONTROL_IMPORT_MENU_ITEM_IMPORT_MEDIA": "Import media",
  "HEAD_CONTROL_IMPORT_MENU_ITEM_IMPORT_FOLDER_WITH_MEDIA": "Import folder with media",
  "HEAD_CONTROL_IMPORT_MENU_ITEM_AMAZON_S3_BUCKET": "Amazon S3 Bucket",
  "HEAD_CONTROL_IMPORT_MENU_ITEM_DROPBOX": "Dropbox",
  "HEAD_CONTROL_IMPORT_MENU_ITEM_GOOGLE_DRIVE": "Google Drive",
  "HEAD_CONTROL_IMPORT_MENU_ITEM_URL_INPUT": "URL input",
  "HEADER_MENU_LIST_ITEM_SAVE_PROJECT_AS": "Save project as..",
  "HEADER_MENU_LIST_ITEM_DELETE": "Delete",
  "HEADER_MENU_LIST_ITEM_RENAME": "Rename",
  "HEADER_PROFILE_MENU_ITEM_ADD_LICENSE_KEY": "Add license key",
  "HEADER_PROFILE_MENU_ITEM_LOG_OUT": "Log out",
  "HEADER_TEXT_DO_YOU_WANT_TO_LEAVE_EDITOR": "Do you want to leave the editor?",
  "HEADER_BTN_BACK_TO_MY_ACC": "Back to My Account",
  "HEADER_BTN_BACK_TO_MY_ACC_TOOLTIP": "Back to My Account",
  "HEADER_BTN_HELP": "Help",
  "HEADER_BTN_HELP_TOOLTIP": "Help",
  "IMAGES_TEXT_NATIVE_IMAGES": "Native image",
  "IMAGES_TEXT_PEXELS": "Pexels",
  "IMAGES_TEXT_UNSPLASH": "Unsplash",
  "IMAGES_HEADER_CAPTION": "Images",
  "IMAGES_PLACEHOLDER_SEARCH_IN": "Search in",
  "IMAGES_PLACEHOLDER_SELECT_COLLECTION": "Select collection",
  "IMAGES_TEXT_ALL_CATEGORIES": "All collections",
  "IMAGES_TEXT_NO_RESULTS_FOUND": "No results found",
  "IMAGES_TEXT_CANNOT_FIND_RESULTS": "It seems we can’t find any results based on your search.",
  "IMAGES_TAB_AI_GENERATED": "Ai-Generated",
  "IMAGES_TAB_SEARCH": "Search",
  "IMPORT_FROM_URL_TEXT_IMPORT_MEDIA_FROM_URL": "Import media from URL",
  "IMPORT_FROM_URL_PLACEHOLDER_CLICK_TO_LINK_FILE": "Click here to link to a file",
  "IMPORT_FROM_URL_BTN_IMPORT": "Import",
  "IMPORT_FROM_URL_BTN_IMPORT_TOOLTIP": "Import",
  "LAYER_CONTROL_BTN_MOVE_UP": "Move Up",
  "LAYER_CONTROL_BTN_MOVE_UP_TOOLTIP": "Move Up",
  "LAYER_CONTROL_BTN_DRAG_LAYER": "Drag Layer",
  "LAYER_CONTROL_BTN_DRAG_LAYER_TOOLTIP": "Drag Layer",
  "LAYER_CONTROL_BTN_MOVE_DOWN": "Move Down",
  "LAYER_CONTROL_BTN_MOVE_DOWN_TOOLTIP": "Move Down",
  "LAYER_CONTROL_BTN_DELETE": "Delete",
  "LAYER_CONTROL_BTN_DELETE_TOOLTIP": "Delete",
  "LAYER_CONTROL_BTN_HIDE": "Hide",
  "LAYER_CONTROL_BTN_HIDE_TOOLTIP": "Hide",
  "LAYER_CONTROL_BTN_SHOW": "Show",
  "LAYER_CONTROL_BTN_SHOW_TOOLTIP": "Show",
  "LAYER_CONTROL_BTN_MUTE": "Mute",
  "LAYER_CONTROL_BTN_MUTE_TOOLTIP": "Mute",
  "LAYER_CONTROL_BTN_UNMUTE": "Unmute",
  "LAYER_CONTROL_BTN_UNMUTE_TOOLTIP": "Unmute",
  "LEFT_TOOLBAR_MENU_BTN_MY_MEDIA": "My Media",
  "LEFT_TOOLBAR_MENU_BTN_MY_MEDIA_TOOLTIP": "My Media",
  "LEFT_TOOLBAR_MENU_BTN_TRANSITIONS": "Transitions",
  "LEFT_TOOLBAR_MENU_BTN_TRANSITIONS_TOOLTIP": "Transitions",
  "LEFT_TOOLBAR_MENU_BTN_IMAGES": "Images",
  "LEFT_TOOLBAR_MENU_BTN_IMAGES_TOOLTIP": "Images",
  "LEFT_TOOLBAR_MENU_BTN_AUDIO": "Audio",
  "LEFT_TOOLBAR_MENU_BTN_AUDIO_TOOLTIP": "Audio",
  "LEFT_TOOLBAR_MENU_BTN_TEXT": "Text",
  "LEFT_TOOLBAR_MENU_BTN_TEXT_TOOLTIP": "Text",
  "LEFT_TOOLBAR_MENU_BTN_DESIGN": "Design",
  "LEFT_TOOLBAR_MENU_BTN_DESIGN_TOOLTIP": "Design",
  "LEFT_TOOLBAR_MENU_BTN_FILTERS": "Filters",
  "LEFT_TOOLBAR_MENU_BTN_FILTERS_TOOLTIP": "Filters",
  "LEFT_TOOLBAR_MENU_BTN_DESIGN_FILTERS": "Filters",
  "MEDIA_FORM_TITLE_SETTINGS_IMAGE": "Image settings",
  "MEDIA_FORM_CAPTION_POSITION": "Position",
  "MEDIA_FORM_CAPTION_ROTATION": "Rotation",
  "MEDIA_FORM_CAPTION_SETTINGS": "Settings",
  "MEDIA_FORM_CAPTION_OUTLINE": "Outline",
  "MEDIA_FORM_CAPTION_SHADOW": "Shadow",
  "MEDIA_FORM_CAPTION_SIZE": "Size",
  "MEDIA_FORM_SLIDER_LABEL_OPACITY": "Opacity",
  "MEDIA_HEAD_CONTROL_BTN_FILTERS": "Filter",
  "MEDIA_HEAD_CONTROL_BTN_FILTERS_TOOLTIP": "Filter",
  "MEDIA_HEAD_CONTROL_BTN_VIEW_SETTINGS": "View settings",
  "MEDIA_HEAD_CONTROL_BTN_VIEW_SETTINGS_TOOLTIP": "View settings",
  "MEDIA_UPLOADER_TEXT_OR": "or",
  "MEDIA_UPLOADER_TEXT_DND_YOUR_MEDIA": "Drag and drop your media here",
  "MEDIA_UPLOADER_TEXT_IMPORT_MEDIA": "import media",
  "MEDIA_PREVIEW_TEXT_IMPORTING": "Importing",
  "MEDIA_PREVIEW_TEXT_RENDERING": "Rendering",
  "MEDIA_INFO_LIST_ITEM_RESOLUTION": "Resolution",
  "MEDIA_INFO_LIST_ITEM_CODEC": "Codecs",
  "MEDIA_INFO_LIST_ITEM_FRAME_RATE": "Frame rate",
  "MEDIA_INFO_LIST_ITEM_HZ_STEREO": "Hz - Stereo",
  "MEDIA_INFO_LIST_ITEM_DURATION": "Duration",
  "MEDIA_INFO_LIST_ITEM_TYPE": "Type",
  "MEDIA_INFO_LIST_ITEM_AUDIO": "Audio",
  "MEDIA_INFO_LIST_ITEM_SIZE": "Size",
  "MEDIA_INFO_LIST_TASK_STATUS": "Task status",
  "MEDIA_LIST_HEADER_TEXT_MEDIA": "Media",
  "MEDIA_BTN_LOAD_MORE_MEDIA": "Load more Media",
  "MEDIA_BTN_LOAD_MORE_MEDIA_TOOLTIP": "Load more Media",
  "MEDIA_BTN_LOADING": "Loading",
  "MEDIA_BTN_LOADING_TOOLTIP": "Loading",
  "MEDIA_VIDEO_FORM_CAPTION_CHROMA_KEY": "Chroma Key",
  "MEDIA_VIDEO_FORM_CAPTION_POSITION": "Position",
  "MEDIA_VIDEO_FORM_CAPTION_SOUND": "Sound",
  "MEDIA_VIDEO_FORM_CAPTION_SIZE": "SIZE",
  "MEDIA_VIDEO_FORM_TITLE_SETTINGS_MEDIA": "Media settings",
  "MEDIA_VIDEO_FORM_BTN_RESET_ALL_SETTINGS": "Reset all settings",
  "MEDIA_VIDEO_FORM_BTN_RESET_ALL_SETTINGS_TOOLTIP": "Reset all settings",
  "MEDIA_VIDEO_FORM_SLIDER_LABEL_SIMILARITY": "Similarity",
  "MEDIA_VIDEO_FORM_SLIDER_LABEL_SMOOTHNESS": "Smoothness",
  "MEDIA_VIDEO_FORM_SLIDER_LABEL_SPILL_REDUCTION": "Key Color Spill Reduction",
  "MEDIA_VIDEO_FORM_TOOLTIP_PICK_COLOR_FROM_PREVIEW": "Pick color from the preview",
  "MEDIA_SORT_OPTION_DURATION": "Duration",
  "MEDIA_SORT_OPTION_DATE": "Date",
  "MEDIA_SORT_OPTION_SIZE": "Size",
  "MEDIA_SORT_OPTION_NAME": "Name",
  "MEDIA_SORT_GROUP_SORT_BY_TOOLTIP": "Sort By",
  "MEDIA_SORT_GROUP_ORDER_TOOLTIP": "Order",
  "MEDIA_SORT_GROUP_VIEW_TOOLTIP": "View",
  "MEDIA_SORT_OPTION_ASCENDING": "Ascending",
  "MEDIA_SORT_OPTION_DESCENDING": "Descending",
  "MEDIA_SORT_OPTION_LIST": "List",
  "MEDIA_SORT_OPTION_LARGE_ICONS": "Large icons",
  "MEDIA_FILE_FILTERS_LIST_ITEM_MY_RECORDINGS": "My Recordings",
  "MEDIA_FILE_FILTERS_LIST_ITEM_MY_RECORDINGS_TOOLTIP": "My Recordings",
  "MEDIA_FILE_FILTERS_LIST_ITEM_MY_MEDIA": "My Media",
  "MEDIA_FILE_FILTERS_LIST_ITEM_MY_MEDIA_TOOLTIP": "My Media",
  "MEDIA_CONFIRM_DESC_ARE_YOU_SURE_TO_REMOVE_SELECTED": "Are you sure you want to remove selected item(s)?",
  "MEDIA_CONFIRM_TITLE_BROWSER_FOR_FOLDER": "Browse for folder",
  "MEDIA_CONFIRM_BTN_DELETE": "Delete",
  "MEDIA_CONFIRM_BTN_DELETE_TOOLTIP": "Delete",
  "MEDIA_CONFIRM_TITLE_DELETE": "Delete",
  "MEDIA_TEXT_LOADING": "Loading",
  "MEDIA_BTN_ADD": "Add",
  "MEDIA_BTN_ADD_TOOLTIP": "Add",
  "MEDIA_PROGRESS_TEXT_LOADING": "Loading",
  "MEDIA_PROGRESS_TEXT_SAVING": "Saving",
  "MEDIA_PROGRESS_TEXT_CONVERT": "Convert",
  "MEDIA_PROGRESS_TEXT_TRANSFER": "Transfer",
  "MEDIA_PROGRESS_TEXT_ANALYSIS": "Analysis",
  "MEDIA_MENU_ITEM_SELECT_FOLDER": "Select Folder",
  "MEDIA_MENU_ITEM_MEDIA": "Media",
  "MEDIA_MENU_ITEM_MOVE_TO": "Move to",
  "MEDIA_MENU_ITEM_SETTINGS": "Settings",
  "MEDIA_MENU_ITEM_DELETE": "Delete",
  "MEDIA_MENU_LABEL_MOVE_TO": "Move to",
  "MEDIA_CONFIRM_BTN_OK": "OK",
  "MEDIA_CONFIRM_BTN_OK_TOOLTIP": "OK",
  "MOBILE_PLUG_TEXT_HTML_VIDEO_EDITOR": "HTML5 Video Editor",
  "MOBILE_PLUG_TEXT_TABLETS_MOBILES_ARENT_SUPPORTED": "Tablets and mobile are not supported yet :(",
  "MOBILE_PLUG_TEXT_WORKS_WELL_ON_THE_PC": "Video Editor works well on your computer",
  "MOBILE_PLUG_LINK_TEXT_GO_BACK_TO_WEB": "Go back to website",
  "NAVIGATION_TAB_EDITOR": "Editor",
  "NAVIGATION_TAB_EDITOR_TOOLTIP": "Editor",
  "NAVIGATION_TAB_PROJECTS": "Projects",
  "NAVIGATION_TAB_PROJECTS_TOOLTIP": "Projects",
  "OFFLINE_MODE_PLUG_TEXT_EDITING_UNAVAILABLE_UNTIL_CONNECT": "Editing is unavailable until the connection is restored.",
  "OFFLINE_MODE_PLUG_TEXT_LAST_SAVED": "Last saved",
  "OFFLINE_MODE_PLUG_TEXT_MIN_AGO": "min ago",
  "OFFLINE_MODE_PLUG_TEXT_JUST_NOW": "just now",
  "PROJECTS_BTN_NEW_PROJECTS": "New project",
  "PROJECTS_BTN_NEW_PROJECTS_TOOLTIP": "New project",
  "PROJECTS_BTN_NEW_PROJECT": "New project",
  "PROJECTS_BTN_IMPORT": "Import",
  "PROJECTS_BTN_IMPORT_TOOLTIP": "Import",
  "PROJECTS_IMPORT_CONFIRM_TEXT_YES": "Yes",
  "PROJECTS_IMPORT_BTN_OK": "OK",
  "PROJECTS_IMPORT_BTN_OK_TOOLTIP": "OK",
  "PROJECTS_IMPORT_TEXT_CANCEL": "Cancel",
  "PROJECTS_TITLE_TEXT_COPY": "Copy",
  "PROJECTS_BTN_SAVE": "Save",
  "PROJECTS_BTN_SAVE_TOOLTIP": "Save",
  "PROJECTS_TITLE_TEXT_RENAME": "Rename",
  "PROJECTS_TITLE_TEXT_DELETE": "Delete",
  "PROJECTS_CONFIRM_BTN_DELETE": "Delete",
  "PROJECTS_PROGRESS_TEXT_LOADING": "Loading",
  "PROJECTS_PROGRESS_TEXT_SEARCHING": "Searching",
  "PROJECTS_IMPORT_CONFIRM_TITLE_DO_YOU_WANNA_STOP_IMPORT": "Do you want to stop importing?",
  "PROJECTS_TEXT_NOTHING_FOUND": "Nothing found",
  "PROJECTS_TEXT_CREATE_A_NEW_PROJECT": "Create a beautiful new project",
  "PROJECTS_TEXT_CAPTION": "Projects",
  "PROJECTS_SAVE_TITLE_SAVE_PROJECT_AS": "Save project as..",
  "PROJECTS_SAVE_INPUT_PLACEHOLDER_PROJECT_NAME": "Project name",
  "PROJECTS_UPLOAD_TEXT_CLICK_TO_UPLOAD_DND_FILE": "Click to upload or, drag and drop a project file here",
  "PROJECTS_UPLOAD_TEXT_YOU_CAN_UPLOAD_ONE_FILE": "You can upload one file at a time only",
  "PROJECTS_PREFIX_TEXT_EDITED": "Edited",
  "PROJECTS_CONFIRM_TITLE_ARE_YOU_SURE_TO_DELETE": "Are you sure you want to delete",
  "PROJECTS_CONFIRM_DESC_PROJECT_WILL_BE_DELETED_IMMEDIATELY": "This project will be deleted immediately. You can’t undo this action.",
  "PROJECTS_CONFIRM_TITLE_PROJECT": "Project",
  "PROJECTS_IMPORT_ONLY_ONE_FILE_TO_UPLOAD": "Only one file can be uploaded",
  "PROJECTS_IMPORT_INVALID_FILE_EXTENSION": "Invalid file extension. Should be *.edl.zip",
  "PROJECTS_IMPORT_PLACEHOLDER_ENTER_YOUR_VALUE": "Enter your value",
  "PROJECTS_IMPORT_FORM_LABEL_CREATE_FOLDER_IN_MEDIA": "Create a folder for this project in Media",
  "PROJECTS_IMPORT_TEXT_OPEN_EDITOR": "Open Editor",
  "PROJECTS_IMPORT_TEXT_TRY_AGAIN": "Try again",
  "PROJECTS_IMPORT_TITLE_SELECT_FRAME_RATE": "Select frame rate for the project",
  "PREVIEW_PLACEHOLDER_FILES_FROM_MEDIA_PLAYED_HERE": "Files from media are played back here",
  "PREVIEW_BTN_PREV_TOOLTIP": "Prev",
  "PREVIEW_BTN_PREV": "Prev",
  "PREVIEW_BTN_PLAY_TOOLTIP": "Play",
  "PREVIEW_BTN_PLAY": "Play",
  "PREVIEW_BTN_STOP_TOOLTIP": "Stop",
  "PREVIEW_BTN_STOP": "Stop",
  "PREVIEW_BTN_NEXT_TOOLTIP": "Next",
  "PREVIEW_BTN_NEXT": "Next",
  "PREVIEW_BTN_VOLUME_TOOLTIP": "Volume",
  "PREVIEW_BTN_VOLUME": "Volume",
  "PREVIEW_BTN_FULL_SCREEN": "Full screen",
  "PREVIEW_BTN_FULL_SCREEN_TOOLTIP": "Full screen",
  "SAVE_SETTINGS_LIST_ITEM_DURATION": "Duration",
  "SAVE_SETTINGS_LIST_ITEM_CODEC": "Codec",
  "SAVE_SETTINGS_LIST_ITEM_FRAME_RATE": "Frame Rate",
  "SAVE_SETTINGS_LIST_ITEM_HZ_STEREO": "Hz - Stereo",
  "SAVE_SETTINGS_LIST_ITEM_RESOLUTION": "Resolution",
  "SAVE_SETTINGS_LIST_ITEM_AUDIO": "Audio",
  "SAVE_SETTINGS_LIST_ITEM_SIZE_APPROXIMATE": "Size (approximate)",
  "SAVE_SETTINGS_LINK_TO_TRANSCODING_WILL_BE_LOST": "Link to transcoding file will be lost",
  "SAVE_SETTINGS_CAPTION_PUBLISH_SETTINGS": "Publish settings",
  "SAVE_SETTINGS_CAPTION_VIDEO_RENDER_COMPLETE": "Video Render Complete",
  "SAVE_SETTINGS_INPUT_PLACEHOLDER_RENDER_NAME": "Enter name",
  "SAVE_SETTINGS_BTN_RENDER_START": "Start",
  "SAVE_SETTINGS_BTN_RENDER_START_TOOLTIP": "Start",
  "SAVE_SETTINGS_BTN_BACK_TO_MY_ACC": "Back to My Account",
  "SAVE_SETTINGS_BTN_BACK_TO_MY_ACC_TOOLTIP": "Back to My Account",
  "SAVE_SETTINGS_BTN_OK": "OK",
  "SAVE_SETTINGS_BTN_OK_TOOLTIP": "OK",
  "SAVE_SETTINGS_BTN_CANCEL": "Cancel",
  "SAVE_SETTINGS_BTN_CANCEL_TOOLTIP": "Cancel",
  "SAVE_SETTINGS_BTN_DOWNLOAD": "Download",
  "SAVE_SETTINGS_BTN_DOWNLOAD_TOOLTIP": "Download",
  "SAVE_SETTINGS_BTN_BROWSE": "Browse",
  "SAVE_SETTINGS_BTN_BROWSE_TOOLTIP": "Browse",
  "SAVE_SETTINGS_BTN_COPY": "Copy",
  "SAVE_SETTINGS_BTN_COPY_TOOLTIP": "Copy",
  "SAVE_SETTINGS_TEXT_TITLE": "Title",
  "SAVE_SETTINGS_TEXT_LOADING": "Loading",
  "SAVE_SETTINGS_TEXT_VIDEO_SUBMITTED_TO_YOUR_ACCOUNT": "This video has been submitted to your account and should be available soon.",
  "SEARCH_DROP_DOWN_MENU_ITEM_ALL": "All",
  "SEARCH_INPUT_PLACEHOLDER_SEARCH": "Search",
  "SETTINGS_HEAD_TEXT_DESCRIPTION": "Description",
  "SETTINGS_HEAD_BTN_DELETE": "Delete",
  "SETTINGS_HEAD_BTN_DELETE_TOOLTIP": "Delete",
  "SETTINGS_HEAD_BTN_CLOSE": "Close",
  "SETTINGS_HEAD_BTN_CLOSE_TOOLTIP": "Close",
  "SETTINGS_SIZE_ICON_TEXT_WIDTH": "Width",
  "SETTINGS_SIZE_ICON_TEXT_HEIGHT": "Height",
  "SETTINGS_SIZE_ICON_TEXT_KEEP_ASPECT_RATIO": "Keep the aspect ratio",
  "SETTINGS_BTN_FIT_TO_FRAME": "Fit to frame",
  "SETTINGS_BTN_FIT_TO_FRAME_TOOLTIP": "Fit to frame",
  "SETTINGS_BTN_FILL_FRAME": "Fill frame",
  "SETTINGS_BTN_FILL_FRAME_TOOLTIP": "Fill frame",
  "SETTINGS_TEXT_AUTO": "auto",
  "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_NONE": "Text transform none",
  "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_NONE_TOOLTIP": "Text transform none",
  "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_UPPERCASE": "Text transform uppercase",
  "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_UPPERCASE_TOOLTIP": "Text transform uppercase",
  "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_LOWERCASE": "Text transform lowercase",
  "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_LOWERCASE_TOOLTIP": "Text transform lowercase",
  "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_CAPITALIZE": "Text transform capitalize",
  "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_CAPITALIZE_TOOLTIP": "Text transform capitalize",
  "SETTINGS_TEXT_BTN_FONT_FAMILY": "Font family",
  "SETTINGS_TEXT_BTN_FONT_FAMILY_TOOLTIP": "Font family",
  "SETTINGS_TEXT_BTN_FONT_SIZE": "Font siz",
  "SETTINGS_TEXT_BTN_FONT_SIZE_TOOLTIP": "Font size",
  "SETTINGS_TEXT_BTN_BOLD": "Bold",
  "SETTINGS_TEXT_BTN_BOLD_TOOLTIP": "Bold",
  "SETTINGS_TEXT_BTN_ITALIC": "Italic",
  "SETTINGS_TEXT_BTN_ITALIC_TOOLTIP": "Italic",
  "SETTINGS_TEXT_BTN_UNDERLINE": "Underline",
  "SETTINGS_TEXT_BTN_UNDERLINE_TOOLTIP": "Underline",
  "SETTINGS_TEXT_ALIGN_BTN_LEFT": "Left",
  "SETTINGS_TEXT_ALIGN_BTN_LEFT_TOOLTIP": "Left",
  "SETTINGS_TEXT_ALIGN_BTN_RIGHT": "Right",
  "SETTINGS_TEXT_ALIGN_BTN_RIGHT_TOOLTIP": "Right",
  "SETTINGS_TEXT_ALIGN_BTN_CENTER": "Center",
  "SETTINGS_TEXT_ALIGN_BTN_CENTER_TOOLTIP": "Center",
  "SETTINGS_TEXT_ALIGN_BTN_JUSTIFY": "Justify",
  "SETTINGS_TEXT_ALIGN_BTN_JUSTIFY_TOOLTIP": "Justify",
  "SETTINGS_TEXT_POSITION_BTN_TOP": "Top",
  "SETTINGS_TEXT_POSITION_BTN_TOP_TOOLTIP": "Top",
  "SETTINGS_TEXT_POSITION_BTN_TOP_LEFT": "Top left",
  "SETTINGS_TEXT_POSITION_BTN_TOP_LEFT_TOOLTIP": "Top left",
  "SETTINGS_TEXT_POSITION_BTN_TOP_RIGHT": "Top Right",
  "SETTINGS_TEXT_POSITION_BTN_TOP_RIGHT_TOOLTIP": "Top Right",
  "SETTINGS_TEXT_POSITION_BTN_LEFT": "Left",
  "SETTINGS_TEXT_POSITION_BTN_LEFT_TOOLTIP": "Left",
  "SETTINGS_TEXT_POSITION_BTN_MIDDLE_CENTER": "Middle center",
  "SETTINGS_TEXT_POSITION_BTN_MIDDLE_CENTER_TOOLTIP": "Middle center",
  "SETTINGS_TEXT_POSITION_BTN_RIGHT": "Right",
  "SETTINGS_TEXT_POSITION_BTN_RIGHT_TOOLTIP": "Right",
  "SETTINGS_TEXT_POSITION_BTN_BOTTOM_LEFT": "Bottom left",
  "SETTINGS_TEXT_POSITION_BTN_BOTTOM_LEFT_TOOLTIP": "Bottom left",
  "SETTINGS_TEXT_POSITION_BTN_BOTTOM": "Bottom",
  "SETTINGS_TEXT_POSITION_BTN_BOTTOM_TOOLTIP": "Bottom",
  "SETTINGS_TEXT_POSITION_BTN_BOTTOM_RIGHT": "Bottom",
  "SETTINGS_TEXT_POSITION_BTN_BOTTOM_RIGHT_TOOLTIP": "Bottom right",
  "SETTINGS_ROTATION_TEXT_HORIZONTAL": "Horizontal",
  "SETTINGS_ROTATION_TEXT_VERTICAL": "Vertical",
  "SETTINGS_ROTATION_TEXT_ROTATE": "Rotate",
  "SETTINGS_PLACEHOLDER_ENTER_TEXT": "Enter text",
  "SECTION_PLACEHOLDER_TEXT_SECTION_IN_DEVELOPMENT": "This section is in development",
  "TEXT_FORM_CAPTION_SIZE": "Size",
  "TEXT_FORM_CAPTION_OUTLINE": "Outline",
  "TEXT_FORM_CAPTION_FONT": "Font",
  "TEXT_FORM_CAPTION_SHADOW": "Shadow",
  "TEXT_FORM_TEXT_TITLE_SETTINGS": "Text settings",
  "TEXT_FORM_TEXT_OFFSET": "Offset",
  "TEXT_FORM_TEXT_BLUR": "Blur",
  "TEXT_HEADER_TEXT_CAPTION_TEXT": "Text",
  "TIMELINE_TEXT_DRAG_N_DROP": "Drag & Drop",
  "TIMELINE_TEXT_ADD_LAYER": "+ Add Layer",
  "TIMELINE_TOOLBAR_BTN_UNDO": "Undo",
  "TIMELINE_TOOLBAR_BTN_UNDO_TOOLTIP": "Undo",
  "TIMELINE_TOOLBAR_BTN_REDO": "Redo",
  "TIMELINE_TOOLBAR_BTN_REDO_TOOLTIP": "Redo",
  "TIMELINE_TOOLBAR_BTN_DELETE": "Delete",
  "TIMELINE_TOOLBAR_BTN_DELETE_TOOLTIP": "Delete",
  "TIMELINE_TOOLBAR_BTN_COPY": "Copy clip",
  "TIMELINE_TOOLBAR_BTN_COPY_TOOLTIP": "Copy clip",
  "TIMELINE_TOOLBAR_BTN_CUT": "Cut clip(s)",
  "TIMELINE_TOOLBAR_BTN_CUT_TOOLTIP": "Cut clip(s)",
  "TIMELINE_TOOLBAR_BTN_ADD_MARKER_TOOLTIP": "Add Marker",
  "TIMELINE_TOOLBAR_BTN_ADD_MARKER": "Add Marker",
  "TIMELINE_TOOLBAR_BTN_PASTE_TOOLTIP": "Paste",
  "TIMELINE_TOOLBAR_BTN_PASTE": "Paste",
  "TIMELINE_TOOLBAR_BTN_SETTINGS_TOOLTIP": "Settings",
  "TIMELINE_TOOLBAR_BTN_SETTINGS": "Settings",
  "TIMELINE_TOOLBAR_BTN_VOICE_OVER": "Voice Over",
  "TIMELINE_TOOLBAR_BTN_VOICE_OVER_TOOLTIP": "Voice Over",
  "TIMELINE_TOOLBAR_BTN_WEBCAMERA": "Web camera",
  "TIMELINE_TOOLBAR_BTN_WEBCAMERA_TOOLTIP": "Web camera",
  "TIMELINE_TOOLBAR_BTN_CLICK_TO_CANCEL": "Click to cancel",
  "TIMELINE_TOOLBAR_BTN_CLICK_TO_CANCEL_TOOLTIP": "Click to cancel",
  "TIMELINE_LAYER_TITLE_DRAG_N_DROP": "Drag & Drop",
  "TIMELINE_FOOTER_TEXT_DURATION": "Duration",
  "TIMELINE_FOOTER_TITLE_UNABLE_TO_PUBLISH": "Unable to publish project",
  "TIMELINE_FOOTER_TEXT_VIDEO_TRACK_IS_REQUIRED": "Video track is required",
  "TIMELINE_FOOTER_TITLE_TEXT_SAVE": "Save",
  "TIMELINE_FOOTER_BTN_SAVE": "Save",
  "TIMELINE_FOOTER_BTN_SAVE_TOOLTIP": "Save",
  "TIMELINE_FOOTER_BTN_PUBLISH": "Publish",
  "TIMELINE_FOOTER_BTN_PUBLISH_TOOLTIP": "Publish",
  "TIMELINE_SLIDER_POINTER": "Slider pointer",
  "TIMELINE_SLIDER_POINTER_TOOLTIP": "Slider pointer",
  "TRANSITION_FORM_TITLE_SETTINGS_TRANSITION": "Transition settings",
  "TRANSITION_FORM_SWITCH_TEXT_APPLY_TO_ALL_TRANSITIONS": "Apply to all transitions from the timeline",
  "TRANSITION_FORM_LABEL_TRANSITION_DURATION": "Transition duration:",
  "TRANSITION_LIST_CAPTION_TRANSITION": "Transition",
  "VOICE_OVER_TITLE_LAST_RECORDING": "Last recording",
  "VOICE_OVER_BTN_RETAKE": "Retake",
  "VOICE_OVER_BTN_RETAKE_TOOLTIP": "Retake",
  "VOICE_OVER_BTN_DELETE_FILE": "Delete file",
  "VOICE_OVER_BTN_DELETE_FILE_TOOLTIP": "Delete file",
  "VOICE_OVER_BTN_START_RECORDING": "Start recording",
  "VOICE_OVER_BTN_START_RECORDING_TOOLTIP": "Start recording",
  "VOICE_OVER_BTN_CONNECTION": "Connection...",
  "VOICE_OVER_BTN_CONNECTION_TOOLTIP": "Connection...",
  "VOICE_OVER_TEXT_VOICE_OVER_BTN_FILE_WILL_BE_DELETED_FROM_MEDIA_AND_TIMELINE": "The file will be deleted from the timeline and media.",
  "VOICE_OVER_TEXT_ARE_YOU_SURE": "Are you sure?",
  "VOICE_OVER_TEXT_VOICE_NARRATION": "Voice Narration",
  "VOICE_OVER_TEXT_MIC": "Microphone",
  "VOICE_OVER_LABEL_SELECT_MIC": "Select microphone",
  "VOICE_OVER_MENU_ITEM_NO_AUDIO": "No audio",
  "VOICE_OVER_TEXT_MUTE_TIMELINE": "Mute timeline",
  "VOICE_OVER_TEXT_REWIND_SLIDER_AT_START_OF_RECORD": "Rewind slider at the start of record",
  "VOICE_OVER_TEXT_COUNTDOWN": "Countdown",
  "VOICE_OVER_BTN_TEST_MIC": "Test Mic",
  "VOICE_OVER_BTN_TEST_MIC_TOOLTIP": "Test Mic",
  "VOICE_OVER_BTN_RECORDING": "Recording...",
  "VOICE_OVER_BTN_RECORDING_TOOLTIP": "Recording...",
  "VOICE_OVER_BTN_PLAYING": "Playing...",
  "VOICE_OVER_BTN_PLAYING_TOOLTIP": "Playing...",
  "VOICE_OVER_BTN_YES": "Yes",
  "VOICE_OVER_BTN_YES_TOOLTIP": "Yes",
  "VOICE_OVER_BTN_NO": "No",
  "VOICE_OVER_BTN_NO_TOOLTIP": "No",
  "VOICE_OVER_BTN_STOP": "Stop",
  "VOICE_OVER_BTN_STOP_TOOLTIP": "Stop",
  "VOICE_OVER_BTN_DELETE": "Delete",
  "VOICE_OVER_BTN_DELETE_TOOLTIP": "Delete",
  "VOICE_OVER_VOLUME_TEXT_AUTO_ADJUSTING": "Auto adjusting",
  "WEBCAM_TEXT_RECORD_VIDEO": "Record video",
  "ZOOM_SLIDER_BTN_DECREASE_ZOOM_TOOLTIP": "Decrease zoom",
  "ZOOM_SLIDER_BTN_DECREASE_ZOOM": "Decrease zoom",
  "ZOOM_SLIDER_BTN_INCREASE_ZOOM_TOOLTIP": "Increase zoom",
  "ZOOM_SLIDER_BTN_INCREASE_ZOOM": "Increase zoom",
  "SETTINGS_HOTKEYS": "Hotkeys settings",
  "PRESET": "Preset",
  "DEFAULT": "Default",
  "ADD_NEW": "Add New",
  "SEARCH_COMMAND": "Search command",
  "COMMAND": "Command",
  "SHORTCUTS": "Shortcuts",
  "ALL": 'All',
  "TIMELINE": "Timeline",
  "TOOLS": "Tools",
  "PREVIEW": "Preview",
  "KEYBOARD_MAPPING_PRESET": "Keyboard Mapping Preset",
  "ENTER_PRESET_NAME": "Enter preset name",
  "THIS_PRESET_WILL_BE_DELETED": "This preset will be deleted immediately. You can’t undo this action.",
  "RESET_ALL_TO_DEFAULTS": 'Reset all to defaults',
  "HOTKEY_SETTINGS_UNDO": 'Undo',
  "HOTKEY_SETTINGS_REDO": 'Redo',
  "HOTKEY_SETTINGS_ZOOM_IN": 'Zoom in',
  "HOTKEY_SETTINGS_ZOOM_OUT": 'Zoom out',
  "HOTKEY_SETTINGS_PREV_FRAME": 'Previous frame',
  "HOTKEY_SETTINGS_NEXT_FRAME": 'Next frame',
  "HOTKEY_SETTINGS_ADD_TO_TIMELINE": 'Add to timeline',
  "HOTKEY_SETTINGS_INCREASE_CLIP_VOLUME": 'Increase clip volume',
  "HOTKEY_SETTINGS_DECREASE_CLIP_VOLUME": 'Decrease clip volume',
  "HOTKEY_SETTINGS_RIPPLE_DELETE": 'Ripple delete',
  "HOTKEY_SETTINGS_RIPPLE_TRIM_PREV": 'Ripple trim previous',
  "HOTKEY_SETTINGS_RIPPLE_TRIM_NEXT": 'Ripple trim next',
  "HOTKEY_SETTINGS_SELECT_CLIPS_AT_CURRENT_POSITION": 'Select clips at current position',
  "HOTKEY_SETTINGS_CUT_CLIPS": 'Cut clip(s)',
  "HOTKEY_SETTINGS_PLAY_PAUSE": 'Play/Pause',
  "HOTKEY_SETTINGS_DELETE": 'Delete',
  "HOTKEY_SETTINGS_COPY": 'Copy',
  "HOTKEY_SETTINGS_PASTE": 'Paste',
  'HOTKEY_SETTINGS_PLAY': 'Play',
  'HOTKEY_SETTINGS_PAUSE': 'Pause',
} }
